<!-- 招生系统基础配置 -- 中考科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form :model="form" :rules="rules" ref="form" label-width="200rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="科目名称" prop="subject_name">
        <el-input placeholder="请输入" v-model="form.subject_name"></el-input>
      </el-form-item>
    </el-form>
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data(){
    return{
      form:{
        subject_name: '',
      },
      rules:{
        subject_name:[{required:true,message:"请输入科目名称"},{pattern:/^[\u4e00-\u9fa5\w]{1,30}$/,message: "请输入30字以内的汉字，数字和字母"}],
      },
    }
  },
  methods:{
    // 提交数据
    submit(){
      this.$refs.form.validate((res,e,p)=>{
        if (res){
          let params = {...this.form}
          this.$_register.post("/api/recruit/subject/", {...params}).then(res=>{
            this.$message({
              type: 'success',
              message: res.data.msg,
              onClose: () => {
                this.$store.commit("setPage",1);
                this.$router.back();
              }
            });
          })
        }
      })
    },
  }
}
</script>

<style scoped>

.form{
  padding-top: 70rem;
}
::v-deep .el-form .el-input{
  min-width: 250rem;
}
</style>
